<template>
  <div class="profile">
    <div v-if="userPersonalProfile">
      <div class="eh-flex-container" style="align-items: center">
        <button
          v-if="!profileEditable"
          @click="profileEditable = true"
          class="eh-action-button"
        >
          {{ $t("healthProfessional.patientList.personalDataTab.editProfile") }}
        </button>
        <button
          v-if="profileEditable"
          @click="saveUserPersonalProfile"
          class="eh-action-button"
        >
          {{ $t("base.basic.save") }}
        </button>
        <button v-if="profileEditable" @click="cancel" class="eh-action-button">
          {{ $t("base.basic.cancel") }}
        </button>
        <!-- <b-icon
            @click="showUserSetting = true"
            class="h3"
            style="cursor: pointer; margin-top: 10px"
            icon="gear-fill"
        ></b-icon> -->
      </div>
      <div
        v-if="$productSettings.specialist.hasParticipantTag"
        style="margin-top: 15px; display: flex; flex-wrap: wrap"
      >
        <div style="display: flex; flex-wrap: wrap" v-if="patientTagList">
          <div
            v-for="(tag, index) in participant.ProjectTagIds"
            :key="'tag-' + index"
          >
            <fp-tag :tag="patientTagList[tag]" />
          </div>
        </div>
        <fp-svg-icon
          @click="showEditPatientTagModal = true"
          style="cursor: pointer; padding: 1px; margin: 5px"
          :size="30"
          v-b-tooltip.hover="
            $t('healthProfessional.patientList.personalDataTab.editTag')
          "
          icon="pencil"
        />
        <fp-tag-selector
          v-model="showEditPatientTagModal"
          :tagListType="$enums.Tag.TagListType.Both.Value"
          :participant="participant"
          @changePatientList="refreshLists"
        />
      </div>
      <div style="width: 90%; margin: auto; padding: 50px 0px">
        <client-personal-profile
          :clipperImg="clipperImg"
          :username="participant.Username"
          @setProfilePicture="changeProfilePicture"
          :disabled="!profileEditable"
          v-model="userPersonalProfile"
        />
      </div>
    </div>
    <div style="text-align: center; margin: 20px" v-else>
      <b-spinner />
    </div>
    <!-- Felhasználó - Felettes kapcsolat megszüntetése -->
    <fp-svg-icon
      v-if="$productSettings.specialist.canRemoveProjectRelation"
      v-b-tooltip.hover="$t('ProjectParticipant.removeProjectRelation')"
      icon="remove-user-relation"
      style="cursor: pointer"
    />
    <!-- Felhasználó törlése a projektről -->
    <fp-svg-icon
      v-if="$productSettings.specialist.canRemoveParticipantFromProject"
      v-b-tooltip.hover="$t('ProjectParticipant.removeFromProject')"
      icon="user-remove"
      style="cursor: pointer"
      @click="removeFromProject"
    />
    <!-- Felhasználó törlése -->
    <fp-svg-icon
      v-if="$productSettings.specialist.canDeleteParticipant"
      icon="user-delete"
      v-b-tooltip.hover="$t('ProjectParticipant.deleteParticipant')"
      style="cursor: pointer"
    />
    <!-- <user-setting v-model="showUserSetting" :userId="participant.UserId" /> -->
  </div>
</template>
<script>
import { AttachmentLogic } from "../../../Logic/Backend/attachment";
import { ProjectLogic } from "../../../Logic/Backend/project";
import { TagLogic } from "../../../Logic/Backend/tag";
import { UserProfileLogic } from "../../../Logic/Backend/user-profile";
import ClientPersonalProfile from "../../Profile/ClientPersonalProfile.vue";
//import UserSetting from "@/components/participant-list/tabs/tabs-components/PersonalProfile/UserSetting.vue";

export default {
  components: {
    ClientPersonalProfile,
    /* Tag, */
    //UserSetting,
  },
  data() {
    return {
      userPersonalProfile: null,
      clipperImg: null,
      userFormBuilder: null,
      tagListById: null,
      patientTagList: null,
      profileEditable: false,
      profileImgCanvas: null,
      showUserSetting: null,
      showEditPatientTagModal: null,
    };
  },
  props: {
    participant: Object,
    project: Object,
    canDeletePatientFromProject: Boolean,
    canDeleteUser: Boolean,
  },
  watch: {
    "participant.ProjectTagIds": async function (input) {
      if (input) {
        //profile adatok frissítés
        await this.getUserProfile();
        await this.getTagListByIds(input);
      }
    },
    async participant(input) {
      this.profileEditable = false;
      if (input) {
        //profile adatok frissítés
        await this.getUserProfile();
      }
    },
  },
  methods: {
    async refreshLists(currentParticipant) {
      this.$emit("changePatientList", currentParticipant);
    },
    //profil adatok kérés
    async getUserProfile() {
      //console.log("getProfile");
      //kérés indítása
      const profileResponse = await UserProfileLogic.getPatientProfile(
        this.participant.UserId,
        "PersonalProfile"
      );
      //sikeres kérés?
      if (!profileResponse.Code) {
        //kapott profil adat tárolás
        this.userPersonalProfile = profileResponse;
        //profil kép tárolás kép id alapján vagy default megadása
        this.clipperImg = this.userPersonalProfile.ProfilePictureId
          ? await this.getUserProfilePicture(
              this.userPersonalProfile.ProfilePictureId
            )
          : require("@/assets/annonym-profil-icon.png");
      } else {
        this.$bvToast.toast(profileResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t(
              "healthProfessional.patientList.personalDataTab.errorName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //profil kép kérés
    async getUserProfilePicture(pictureId) {
      //kérés indítás
      const pictureResponse = await AttachmentLogic.downloadAttachment(
        pictureId
      );
      console.log(pictureResponse);
      //sikeres kérés?
      if (!pictureResponse.Code) {
        //kapott kép blob tárolása
        const imgBlob = await pictureResponse.blob();
        //vissza térés az adott blob alapján generált url-lel
        return URL.createObjectURL(imgBlob);
      } else {
        this.$bvToast.toast(pictureResponse.Message, {
          title: this.$t(
            "requestResponse.attachment.errorDownloadProfilePhoto"
          ),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
        //visszatérés a default kép url-lel
        return require("@/assets/annonym-profil-icon.png");
      }
    },
    async changeProfilePicture(canvas) {
      this.profileImgCanvas = canvas;
    },
    //profil személyes adatok, profilkép mentése
    async saveUserPersonalProfile() {
      /* const validation = await this.$refs.personalProfile.$validate();
      if (validation) { */
      //kép feltöltési válasz
      var uploadResult = {};
      //van új profilkép canvas?
      if (this.profileImgCanvas) {
        //va, canvas előkészítése a blob készítésre
        const blobImg = await new Promise((resolve) =>
          this.profileImgCanvas.toBlob(resolve)
        );
        //képet blobbá alakítjuk
        var blob = new Blob([blobImg]);
        //sikeres blob?
        if (blob) {
          //kép feltöltés kérés
          uploadResult = await AttachmentLogic.uploadAttachment(
            blob,
            this.$enums.AttachmentTypeName.UserProfile.TypeName,
            this.participant[
              this.$enums.AttachmentTypeName.UserProfile.EntityIdValueKey
            ]
          );
        }
        //sikeres kép feltöltés kérés?
        if (!uploadResult.Code) {
          //előző profilkép file id tárolása szövegként
          const previousProfilePictureId = this.userPersonalProfile
            .ProfilePictureId;
          //új profilkép file id beállítás a profilban
          this.userPersonalProfile.ProfilePictureId = uploadResult.AttachmentId;
          //profil mentés kérés
          await this.editProfile();
          //van előző profilkép file id?
          if (previousProfilePictureId) {
            //előző profilkép file törlés kérés
            await AttachmentLogic.deleteAttachment(previousProfilePictureId);
          }
        } else {
          this.$bvToast.toast(uploadResult.Message, {
            title: this.$t(
              "requestResponse.attachment.errorUploadProfilePhoto"
            ),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      } else {
        //nincs, profil adatok mentés kérés
        await this.editProfile();
      }
      /* } else {
        this.validationErrors = this.$refs.personalProfile.validation.errors;
        this.$bvToast.show("validationErrors");
      } */
    },
    async editProfile() {
      const editProfileResponse = await UserProfileLogic.setUserProfile(
        { PersonalProfile: this.userPersonalProfile },
        this.participant.UserId
      );
      if (!editProfileResponse.Code) {
        this.profileEditable = false;
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("requestResponse.basic.successSave"),
            variant: "success",
            solid: true,
          }
        );
        await this.getUserProfile();
      } else {
        this.$bvToast.toast(editProfileResponse.Message, {
          title: this.$t("requestResponse.basic.erroModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async cancel() {
      this.profileEditable = false;
      await this.getUserProfile();
    },

    async removeFromProject() {
      //this.$emit("delete-relationship", this.participant.RelationshipId);
      if (
        confirm(
          "Biztosan eltávolítja a " +
            this.participant.Name +
            " nevű beteget a projektről? (Ennek hatására a felhasználó fiókja megmarad, csak a projekten nem fog szerepelni.)"
        )
      ) {
        const removeResponse = await ProjectLogic.deleteParticipantFromProject(
          this.project.ProjectId,
          this.participant.UserId
        );
        if (!removeResponse.Code) {
          this.$emit("refreshList");
        } else {
          //TODO: hiba
        }
      }
    },

    async getTagListByIds(tagIds) {
      const getResponse = await TagLogic.getTags({ Ids: tagIds });
      if (!getResponse.Code) {
        const temp = {};
        getResponse.forEach((t) => {
          temp[t.TagId] = t;
        });
        this.patientTagList = temp;
      } else {
        //TODO: hiba
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //profil adatok betöltése
    await this.getUserProfile();
    if (this.participant.ProjectTagIds) {
      await this.getTagListByIds(this.participant.ProjectTagIds);
    }
  },
};
</script>
